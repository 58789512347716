import { CustomMessageBannerInfo } from '@app/home/models/custom-message-banner-info.model';
import { createAction, props } from '@ngrx/store';
import { CustomMessage } from '@usf/user-types/custom-message';
import { User } from '@usf/user-types/user';
import { Preferences, UserPreferences } from '@usf/user-types/user-preference';
import { UserPreferencesV2 } from '@usf/user-types/user-preferencev2';
import { UserProfile } from '@usf/user-types/user-profile';
import { InteractBannerInfo } from '@app/home/models/interact-banner-info.model';
import { SiteCustomizationProfile } from '@usf/user-types/site-customization';
import { CustomerUser } from '@app/manage-users/models/customer-user.model';

export const getUserByToken = createAction(
  '[User] Get User By Token',
  (impersonatorID: string = '') => ({ impersonatorID }),
);

export const getUserByTokenSuccess = createAction(
  '[User Effect] Get User By Token Success',
  props<{ user: User; profiles: UserProfile[] }>(),
);

export const getUserCustomization = createAction(
  '[User Effect] Get User Customization',
);

export const getUserCustomizationSuccess = createAction(
  '[User Effect] Get User Customization Success',
  props<{ customization: SiteCustomizationProfile }>(),
);

export const getUserCustomizationFail = createAction(
  '[User Effect] Get User Customization Fail',
  props<{ error: any }>(),
);

export const getAllSiteCustomizationProfiles = createAction(
  '[User Effect] Get All Site Customization Profiles',
);

export const getAllSiteCustomizationProfilesSuccess = createAction(
  '[User Effect] Get All Site Customization Profiles Success',
  props<{ customizations: SiteCustomizationProfile[] }>(),
);

export const getAllSiteCustomizationProfilesFail = createAction(
  '[User Effect] Get All Site Customization Profiles Fail',
  props<{ error: any }>(),
);

export const getUserByTokenFail = createAction(
  '[User Effect] Get User By Token Fail',
  props<{ error: any }>(),
);
export const getUserPreferences = createAction(
  '[User Effect] Get User Preferences',
);
export const getUserPreferencesSuccess = createAction(
  '[User Effect] Get User Preferences Success',
  props<{ preferences: UserPreferences }>(),
);
export const getUserPreferencesFail = createAction(
  '[User Effect] Get User Preferences Fail',
  props<{ error: any }>(),
);
export const updateUserPreferences = createAction(
  '[User Effect] Update User Preferences',
  props<{ newPreferences: Preferences; oldPreferences: Preferences }>(),
);
export const updateUserPreferencesSuccess = createAction(
  '[User Effect] Update User Preferences Success',
);
export const updateUserPreferencesFail = createAction(
  '[User Effect] Update User Preferences Fail',
  props<{ error: any; oldPreferences: Preferences }>(),
);
export const getEmailPhoneByUsername = createAction(
  '[User Effect] Get User Email and Phone',
  props<{ username: string }>(),
);
export const getEmailPhoneByUsernameSuccess = createAction(
  '[User Effect] Get User Email and Phone Success',
  props<{ emailAndPhone: any }>(),
);
export const getEmailPhoneByUsernameFail = createAction(
  '[User Effect] Get User Email and Phone Fail',
  props<{ error: any }>(),
);
export const getInteractBanners = createAction(
  '[User Effect] Get User Interact Banners',
);
export const getInteractBannersSuccess = createAction(
  '[User Effect] Get User Interact Banners Success',
  props<{ interactBanners: InteractBannerInfo[] }>(),
);
export const getInteractBannersFail = createAction(
  '[User Effect] Get User Interact Banners Fail',
  props<{ error: any }>(),
);
export const getCustomMessage = createAction(
  '[User Effect] Get User Custom Message',
);
export const getCustomMessageSuccess = createAction(
  '[User Effect] Get User Custom Message Success',
  props<{ customMessage: CustomMessageBannerInfo }>(),
);
export const getCustomMessageFail = createAction(
  '[User Effect] Get User Custom Message Fail',
  props<{ error: any }>(),
);
export const getCustomMessages = createAction(
  '[User Effect] Get User Custom Messages',
);
export const getCustomMessagesSuccess = createAction(
  '[User Effect] Get User Custom Messages Success',
  props<{ customMessages: CustomMessage[] }>(),
);
export const getCustomMessagesFail = createAction(
  '[User Effect] Get User Custom Messages Fail',
  props<{ error: any }>(),
);
export const saveCustomMessage = createAction(
  '[User Effect] Save User Custom Message',
  props<{ customMessage: CustomMessage }>(),
);
export const saveCustomMessageSuccess = createAction(
  '[User Effect] Save User Custom Message Success',
  props<{ customMessage: any }>(),
);
export const saveCustomMessageFail = createAction(
  '[User Effect] Save User Custom Message Fail',
  props<{ error: any }>(),
);
export const deleteCustomMessage = createAction(
  '[User Effect] Delete User Custom Message',
  props<{ messageIds: string[] }>(),
);
export const deleteCustomMessageSuccess = createAction(
  '[User Effect] Delete User Custom Message Success',
  props<{ messageIds: string[] }>(),
);
export const deleteCustomMessageFail = createAction(
  '[User Effect] Delete User Custom Message Fail',
  props<{ error: any }>(),
);
export const inviteNewUser = createAction(
  '[User Effect] Invite New User',
  props<{
    inviteEmail: string;
    customers?: any[];
    fullUserProfile?: any;
    organizationName?: string;
  }>(),
);
export const inviteNewUserSuccess = createAction(
  '[User Effect] Invite New User Success',
  props<{ inviteEmail: string }>(),
);
export const inviteNewUserFail = createAction(
  '[User Effect] Invite New User Fail',
  props<{ error: any; inviteEmail: string }>(),
);
export const searchUserByUsername = createAction(
  '[User Effect] Search User By Username',
  props<{ userName: string }>(),
);
export const searchUserByUsernameSuccess = createAction(
  '[User Effect] Search User By Username Success',
  props<{ user: User }>(),
);
export const searchUserByUsernameFail = createAction(
  '[User Effect] Search User By Username Fail',
  props<{ error: any }>(),
);

export const getUserPreferencesV2 = createAction(
  '[User Preferences V2] Get User Preferences V2',
  props<{ userId: number }>(),
);
export const getUserPreferencesV2Success = createAction(
  '[User Preferences V2] Get User Preferences V2 Success',
  props<{ preferences: UserPreferencesV2 }>(),
);
export const getUserPreferencesV2Fail = createAction(
  '[User Preferences V2] Get User Preferences V2 Fail',
  props<{ error: any }>(),
);

export const updateUserPreferencesV2 = createAction(
  '[User Preferences V2] Update User Preferences V2',
  props<{ preferences: UserPreferencesV2 }>(),
);
export const updateUserPreferencesV2Success = createAction(
  '[User Preferences V2] Update User Preferences V2 Success',
  props<{ preferences: UserPreferencesV2 }>(),
);
export const updateUserPreferencesV2Fail = createAction(
  '[User Preferences V2] Update User Preferences V2 Fail',
  props<{ error: any; oldPreferences: UserPreferencesV2 }>(),
);
export const getCustomerUsers = createAction(
  '[CustomerUser] Get CustomerUsers',
);
export const getCustomerUsersSuccess = createAction(
  '[CustomerUser] Get CustomerUsers Success',
  props<{ customerUsers: CustomerUser[] }>(),
);
export const getCustomerUsersFail = createAction(
  '[CustomerUser] Get CustomerUsers Fail',
  props<{ error: any }>(),
);
export const updateCustomerUser = createAction(
  '[CustomerUser] Update CustomerUser',
  props<{ customerUser: CustomerUser }>(),
);
export const updateCustomerUserSuccess = createAction(
  '[CustomerUser] Update CustomerUser Success',
  props<{ customerUser: CustomerUser }>(),
);
export const updateCustomerUserFail = createAction(
  '[CustomerUser] Update CustomerUser Fail',
  props<{ error: any }>(),
);
