import { createAction, props } from '@ngrx/store';
import { OrderControl } from '../../models/order-control';

export const loadOrderControl = createAction(
  '[OrderControl] Load OrderControl',
);

export const loadOrderControlSuccess = createAction(
  '[OrderControl] Load Success',
  props<{ orderControls: OrderControl[] }>(),
);

export const loadOrderControlFailure = createAction(
  '[OrderControl] Load Failure',
  props<{ error: any }>(),
);
