export enum OrderSubmittedAnalyticsMessages {
  capability = 'orders',
  alertsCapability = 'alerts',
  pageName = 'us foods:r4:order details',
  pageLoadedEvent = 'page loaded',
  orderId = 'Order.id',
  orderStatus = 'order.status',

  editOrderEvent = 'edit order',
  newOrderEvent = 'new order',
  cancelOrderEvent = 'cancel order',
  submitEvent = 'submit order',
  openMethod = 'edit submitted order',
  sharePrintDownloadEvent = 'share print download',
  submitShipTo = 'submit ship to',
  emailDeeplinkEvent = 'email deeplink',
  emailDeeplinkType = 'order:email:deeplink',
}
