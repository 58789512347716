import { SeperatedColumn } from '@shared/models/seperated-columns';
import { nonUsfProductColunms } from './document-helper-models-and-enums';
import { NonUSFProductViewModel } from '@inventory/pages/manage-non-usf-products/models/non-usf-products-view-model';
import {
  addValueToColumns,
  getDocumentCurrency,
  getDocumentRaw,
  getDocumentString,
} from '@shared/helpers/document-creation.helpers';

export const operateOnColumnProductDescription = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: NonUSFProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.productDescLong),
  );
};

export const operateOnColumnProductNumber = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: NonUSFProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.productNumber),
  );
};

export const operateOnColumnVendor = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: NonUSFProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.vendorName ?? ''),
  );
};

export const operateOnColumnBrand = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: NonUSFProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.brand ?? ''),
  );
};

export const operateOnColumnManufacturerNumber = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: NonUSFProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(
      productRow.manufacturerProductNumber
        ? productRow.manufacturerProductNumber
        : '',
    ),
  );
};

export const operateOnColumnPackSize = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: NonUSFProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentRaw(productRow.salesPackSize ?? ''),
  );
};

export const operateOnColumnAverageNetWeight = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: NonUSFProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.averageNetWeight ?? ''),
  );
};

export const operateOnColumnPrice = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: NonUSFProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    productRow.price
      ? getDocumentString(Number(productRow.price).toFixed(2))
      : getDocumentString(''),
  );
};

export const operateOnColumnPriceUom = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: NonUSFProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.isPricePerUnitInd ? 'per Unit' : 'per Case'),
  );
};

export const operateOnColumnUnitDescription = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: NonUSFProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.priceUom ?? ''),
  );
};

export const operateOnColumnUnitsPerCase = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: NonUSFProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.unitsPerCase ?? ''),
  );
};

export const operateOnColumnGlCode = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  productRow: NonUSFProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(productRow.glCode ?? ''),
  );
};

export const nonUsfProductsDocumentFunctionMap = {
  [nonUsfProductColunms.productDescription]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: NonUSFProductViewModel,
  ) => {
    return operateOnColumnProductDescription(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [nonUsfProductColunms.productNumber]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: NonUSFProductViewModel,
  ) => {
    return operateOnColumnProductNumber(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [nonUsfProductColunms.vendor]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: NonUSFProductViewModel,
  ) => {
    return operateOnColumnVendor(columnName, columnMap, downloadableProduct);
  },
  [nonUsfProductColunms.brand]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: NonUSFProductViewModel,
  ) => {
    return operateOnColumnBrand(columnName, columnMap, downloadableProduct);
  },
  [nonUsfProductColunms.manufacturerNumber]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: NonUSFProductViewModel,
  ) => {
    return operateOnColumnManufacturerNumber(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [nonUsfProductColunms.packSize]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: NonUSFProductViewModel,
  ) => {
    return operateOnColumnPackSize(columnName, columnMap, downloadableProduct);
  },
  [nonUsfProductColunms.avgNetWeight]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: NonUSFProductViewModel,
  ) => {
    return operateOnColumnAverageNetWeight(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [nonUsfProductColunms.price]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: NonUSFProductViewModel,
  ) => {
    return operateOnColumnPrice(columnName, columnMap, downloadableProduct);
  },
  [nonUsfProductColunms.priceUom]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: NonUSFProductViewModel,
  ) => {
    return operateOnColumnPriceUom(columnName, columnMap, downloadableProduct);
  },
  [nonUsfProductColunms.unitDescription]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: NonUSFProductViewModel,
  ) => {
    return operateOnColumnUnitDescription(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [nonUsfProductColunms.unitsPerCase]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: NonUSFProductViewModel,
  ) => {
    return operateOnColumnUnitsPerCase(
      columnName,
      columnMap,
      downloadableProduct,
    );
  },
  [nonUsfProductColunms.glCode]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    downloadableProduct: NonUSFProductViewModel,
  ) => {
    return operateOnColumnGlCode(columnName, columnMap, downloadableProduct);
  },
};
