<ion-header
  data-cy="super-user-customers-modal-header"
  class="ion-no-border"
  [ngClass]="
    platform !== platformEnum.desktop
      ? platform === platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons *ngIf="platform === platformEnum.desktop" slot="end">
      <ion-button
        data-cy="super-user-customers-modal-close-button"
        (click)="handleDismiss()"
      >
        <ion-icon
          data-cy="super-user-customers-modal-close-button-icon"
          name="close-outline"
          slot="icon-only"
        ></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-label data-cy="super-user-customers-modal-header-label">
      {{ 'i18n.header.selectCustomer' | translate }}
    </ion-label>

    <ion-searchbar
      data-cy="super-user-customers-modal-searchbar"
      class="search"
      mode="md"
      placeholder="{{ 'i18n.header.search' | translate }}"
      (ionClear)="resetModalData()"
      (keydown.enter)="search($event)"
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>

<!-- Show customer list (or no customers found message) when there is a valid searchkey -->
<ng-container *ngIf="searchKey; else showFavorites">
  <ion-content
    *ngIf="showCustomersDataLoadingSpinner$ | async; else customerSearchResults"
    data-cy="super-user-customers-modal-content"
  >
    <div data-cy="super-user-customers-modal-loading" class="spinner">
      <ion-spinner
        data-cy="super-user-customers-modal-spinner"
        name="circles"
      ></ion-spinner>
    </div>
  </ion-content>

  <ng-template #customerSearchResults>
    <ng-container
      *ngIf="superUserCustomersModalVM$ | async as superUserCustomersModalVM"
    >
      <ng-container
        *ngTemplateOutlet="
          customerList;
          context: { vm: superUserCustomersModalVM, isFavoritesListVM: false }
        "
      ></ng-container>
    </ng-container>
  </ng-template>
</ng-container>

<!-- Show Favorites list when there is no searchkey -->
<ng-template #showFavorites>
  <ng-container *ngIf="favoritesVm$ | async as favoritesVm">
    <ng-container
      *ngTemplateOutlet="
        customerList;
        context: { vm: favoritesVm, isFavoritesListVM: true }
      "
    ></ng-container>
  </ng-container>
</ng-template>

<!-- START CUSTOMER LIST TEMPLATE -->
<ng-template
  #customerList
  let-vm="vm"
  let-isFavoritesListVM="isFavoritesListVM"
>
  <ion-content
    *ngIf="showCustomersDataLoadingSpinner$ | async; else listContent"
    data-cy="super-user-customers-modal-content"
  >
    <div data-cy="super-user-customers-modal-loading" class="spinner">
      <ion-spinner
        data-cy="super-user-customers-modal-spinner"
        name="circles"
      ></ion-spinner>
    </div>
  </ion-content>
  <ng-template #listContent>
    <ion-content
      data-cy="super-user-customers-modal-content"
      [ngClass]="
        platform !== platformEnum.desktop
          ? platform === platformEnum?.tablet
            ? 'ion-content-modal-tablet'
            : 'ion-content-modal-mobile'
          : 'ion-content-modal-desktop'
      "
    >
      <ion-list
        *ngIf="vm.customerSelectionOptions.length; else noCustomersFound"
        data-cy="super-user-customers-modal-options-list"
        (click)="handleSuperUserCustomerSelected()"
      >
        <ion-item *ngIf="isFavoritesListVM" class="favorites-header">
          <span>{{
            'i18n.header.customerSearchModal.favorites' | translate
          }}</span>
          <span
            >&nbsp;{{ getFavoritesCount(vm.customerSelectionOptions) }}</span
          >
          <span class="favorites-limit">/10</span>
          <span class="favorites-subtext"
            >&nbsp;{{
              'i18n.header.customerSearchModal.maxLimit' | translate
            }}</span
          >
        </ion-item>
        <ion-radio-group [(ngModel)]="selectedCustomer">
          <app-super-user-customer-option
            *ngFor="
              let customer of vm.customerSelectionOptions
                | paginate
                  : {
                      itemsPerPage: 15,
                      currentPage,
                      totalItems: vm.customerSelectionOptions.length,
                    };
              let i = index
            "
            [platform]="platform"
            [customer]="customer"
            [customerOptionIndex]="i"
          >
            <div
              *ngIf="superUserFavoriteCustomerFeatureFlag$ | async"
              class="favorite-toggle-wrapper"
              (click)="toggleIsFavorite($event, customer)"
            >
              <ion-icon
                [name]="customer.isFavorite ? 'heart' : 'heart-outline'"
              ></ion-icon>
            </div>
          </app-super-user-customer-option>
        </ion-radio-group>
      </ion-list>

      <ng-template #noCustomersFound>
        <div
          data-cy="super-user-customers-modal-no-results"
          class="no-results"
          *ngIf="!isFavoritesListVM"
        >
          <p data-cy="super-user-customers-modal-no-results-text">
            {{ 'i18n.header.noCustomersFound' | translate }}
          </p>
        </div>
      </ng-template>
    </ion-content>

    <pagination-controls
      *ngIf="vm.customerSelectionOptions.length > 15"
      data-cy="super-user-customers-modal-pagination-controls"
      class="pagination-controls"
      previousLabel="{{ 'i18n.common.prev' | translate }}"
      nextLabel="{{ 'i18n.common.next' | translate }}"
      (pageChange)="changePage($event)"
    >
    </pagination-controls>
  </ng-template>
</ng-template>
<!-- END CUSTOMER LIST TEMPLATE -->
