<ion-header
  class="ion-no-border ion-header-modal-mobile"
  [class.view-selected-prods]="selectedAction === ListActions.viewSelected"
>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        id="back-modal-btn"
        data-cy="selected-product-back-modal-button"
        text=""
      ></ion-back-button>
    </ion-buttons>
    <ion-label data-cy="selected-action-text">
      {{
        useNewCopyProduct && selectedAction === 'Copy'
          ? title
          : selectedAction !== ListActions.viewSelected
            ? selectedAction +
              ' ' +
              selectedProducts.length +
              ' Selected ' +
              (selectedProducts.length > 1 ? 'Products' : 'Product')
            : 'View Selected Products (' + selectedProducts?.length + ')'
      }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="ion-content-modal-mobile"
  [ngClass]="
    selectedAction === ListActions.viewSelected
      ? 'view-selected-prods'
      : 'move-copy-delete'
  "
  tabletTouchMove
>
  <!-- VIEW SELECTED PRODUCTS -->
  <ng-container *ngIf="selectedAction === ListActions.viewSelected">
    <div>
      <ng-container *ngFor="let product of selectedProducts">
        <ion-card class="selected-product">
          <div class="selected-products-card-left">
            <ion-icon name="trash-outline"></ion-icon>
          </div>
          <div class="selected-products-card-right">
            <div>
              <span
                class="product-description"
                data-cy="selected-product-description-text"
              >
                {{ product?.product?.productDescTxtl }}
              </span>
              <span
                [attr.data-cy]="
                  'selected-product-' +
                  product?.product?.productNumber +
                  '-number-text'
                "
                >({{ product?.product?.productNumber }})</span
              >
            </div>
          </div>
        </ion-card>
      </ng-container>
    </div>
  </ng-container>

  <!-- MOVE/DELETE -->
  <div slot="fixed" class="selected-products-memos">
    <span
      *ngIf="selectedAction === ListActions.move"
      data-cy="select-product-memo-move-text"
    >
      {{ 'i18n.lists.whereToMove' | translate }}
      {{
        (selectedProducts.length > 1
          ? 'i18n.lists.theseProducts'
          : 'i18n.lists.thisProduct'
        ) | translate
      }}
    </span>
    <span
      *ngIf="selectedAction === ListActions.copy"
      data-cy="select-product-memo-copy-text"
    >
      {{ 'i18n.lists.whereToCopy' | translate }}
      {{
        (selectedProducts.length > 1
          ? 'i18n.lists.theseProducts'
          : 'i18n.lists.thisProduct'
        ) | translate
      }}
    </span>
    <span
      *ngIf="selectedAction === ListActions.delete"
      data-cy="select-product-memo-delete-text"
    >
      {{ 'i18n.lists.whereToDelete' | translate }}
      {{
        (selectedProducts.length > 1
          ? 'i18n.lists.theseProducts'
          : 'i18n.lists.thisProduct'
        ) | translate
      }}
    </span>
  </div>

  <div
    *ngIf="
      selectedAction === ListActions.move ||
      selectedAction === ListActions.delete ||
      (!useNewCopyProduct && selectedAction === ListActions.copy)
    "
    class="move-copy-delete-container"
  >
    <ion-item-divider></ion-item-divider>
    <div class="groups-list">
      <ng-container *ngFor="let group of selectedGroups">
        <ion-item
          *ngIf="showGroup(group)"
          (click)="onSelectGroup(group.groupState.groupName, group.isSelected)"
          data-cy="click-select-group-item"
        >
          <ion-label data-cy="selected-products-group-name-text">{{
            group.groupState.groupName
          }}</ion-label>
          <ion-icon
            *ngIf="group.isSelected"
            name="checkmark-circle-outline"
          ></ion-icon>
        </ion-item>
      </ng-container>
    </div>
  </div>
  <!-- Copy -->
  <div
    *ngIf="selectedAction === ListActions.copy && useNewCopyProduct"
    class="move-copy-delete-container"
  >
    <ion-item-divider></ion-item-divider>
    <div class="groups-list">
      <ng-container>
        <ion-item
          [disabled]="groups.length === 1"
          detail
          (click)="copyToDifferentGroup()"
          data-cy="click-select-group-item"
        >
          <ion-label data-cy="selected-products-group-name-text">{{
            'i18n.lists.copyProductsModal.differentGroup' | translate
          }}</ion-label>
        </ion-item>
        <ion-item
          *ngIf="!hideCopyToListOption"
          [disabled]="!enableCopyToList"
          detail
          (click)="copySelection($event)"
          data-cy="click-select-group-item"
        >
          <ion-label data-cy="selected-products-group-name-text">{{
            'i18n.lists.copyProductsModal.differentList' | translate
          }}</ion-label>
        </ion-item>
      </ng-container>
    </div>
  </div>
</ion-content>

<ion-footer
  *ngIf="selectedAction !== ListActions.viewSelected"
  class="ion-footer-modal"
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        *ngIf="!(selectedAction === ListActions.copy && useNewCopyProduct)"
        id="submit-modal-btn"
        data-cy="submit-selected-products-modal-button"
        [ngClass]="
          numGroupsSelected < 1
            ? 'usf-fill-disabled-mobile-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="dispatchAction()"
      >
        {{ 'i18n.common.submit' | translate }}
      </ion-button>
      <ion-button
        *ngIf="selectedAction === ListActions.copy && useNewCopyProduct"
        data-cy="click-cancel-selected-products"
        class="usf-outline-green-button"
        (click)="dismissModal()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
