import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { OrderControlDataService } from '../../services/order-control-data.service';
import { OrderControlService } from '../../services/order-control.service';
import {
  loadOrderControl,
  loadOrderControlSuccess,
  loadOrderControlFailure,
} from '../actions/order-control.actions';

@Injectable({
  providedIn: 'root',
})
export class OrderControlEffects {
  loadOrderControl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrderControl),
      concatMap(action =>
        this.orderControlDataService.retrieveOrderControl().pipe(
          map(orderControls =>
            loadOrderControlSuccess({
              orderControls,
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              loadOrderControlFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private orderControlDataService: OrderControlDataService<any>,
    private orderControlService: OrderControlService,
  ) {}

  setOrderControlState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadOrderControlSuccess),
        map(({ orderControls }) => {
          this.orderControlService.setRetrievedOrderControl(orderControls);
        }),
      ),
    { dispatch: false },
  );
}
