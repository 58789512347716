/* eslint-disable no-shadow */
export enum BrowserPageTitle {
  aiLists = 'AI Lists | US Foods',
  login = 'Login | US Foods',
  home = 'Home Page | US Foods',
  reviewOrder = 'Current Order | US Foods',
  submittedOrder = 'Submitted Order | US Foods',
  myOrders = 'My Orders | US Foods',
  search = 'Search | US Foods',
  newSearch = 'Search New | US Foods',
  browseProducts = 'Browse Products | US Foods',
  browseProductCategoriesPage = 'Browse Products Categories | Us Foods',
  browseProductSubCategoriesPage = 'Browse Products Subcategories | US Foods',
  default = 'US Foods',
  orderSubmitted = 'Order Submitted | US Foods',
  orderException = 'Order Exception | US Foods',
  payments = 'Payments | US Foods',
  allInvoices = 'All Invoices | US Foods',
  makePayment = 'Make A Payment | US Foods',
  ARStatements = 'Statements | US Foods',
  paymentActivity = 'Payment Activity | US Foods',
  paymentPreferences = 'Payment Preferences | US Foods',
  adjustPayment = 'Make A Payment | US Foods',
  paymentConfirmation = 'Payment Submitted | US Foods',
  paymentDashboard = 'Seller Dashboard | US Foods',
  reviewPayment = 'Review & Submit | US Foods',
  compare = 'PRODUCT COMPARISON',
  lists = 'Lists | US Foods',
  listDetail = 'List Details | US Foods',
  listManagement = 'List Management | US Foods',
  listUpdates = 'Lists Updates | US Foods',
  searchProductNumbers = 'Search Product Numbers | US Foods',
  dealsForYou = 'Deals For You | US Foods',
  requestCredit = 'Request Credit | US Foods',
  inventory = 'Inventory | US Foods',
  allInventories = 'All Inventories | US Foods',
  createInventory = 'Create Inventory | US Foods',
  inventoryWorksheet = 'Inventory Worksheet | US Foods',
  masterList = 'Master List Details | US Foods',
  recentlyPurchased = 'Recently Purchased | US Foods',
  betterBuys = 'Better Buys | US Foods',
  allAvailableDeals = 'All Available Deals | US Foods',
  manageVendors = 'Manage Vendors | US Foods',
  manageNonUsf = 'Manage Non-US Foods | US Foods',
  searchForSub = 'Search For Substitutes | US Foods',
  orderInsights = 'Order Insights | US Foods',
  foodCost = 'Food Cost Calculator | US Foods',
  scoop = 'Scoop | US Foods',
  supportCenter = 'Support Center | US Foods',
  productCategories = 'Product Categories',
  manageUsers = 'Manage Users | US Foods',
  mpp = 'Menu Profit Pro | US Foods',
}
