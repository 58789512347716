import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Customer, CustomerSummary } from '@usf/customer-types/customer';
import { BehaviorSubject, combineLatest, Observable, take } from 'rxjs';
import { CUSTOMER_ACTIONS } from '../../ngrx-customer/store';
import { UserService } from '@app/user/services';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  isSuperUserCustomersModalOpen$ = new BehaviorSubject<boolean>(false);
  showCustomersDataLoadingSpinner$ = new BehaviorSubject<boolean>(false);
  filteredCustomerSummaries$ = new BehaviorSubject<CustomerSummary[]>([]);

  retrievedCustomer$ = new BehaviorSubject<Customer>({} as Customer);

  private favoritesSubject = new BehaviorSubject<CustomerSummary[]>([]);
  private favoritesSummaries$ = this.favoritesSubject.asObservable();

  constructor(
    private store: Store,
    private userService: UserService,
  ) {}

  searchCustomers(searchKey: string) {
    this.store.dispatch(
      CUSTOMER_ACTIONS.searchCustomers({
        searchKey,
      }),
    );
  }

  setIsSuperUserCustomersModalOpen(isOpen: boolean) {
    this.isSuperUserCustomersModalOpen$.next(isOpen);
  }

  setShowCustomersDataLoadingSpinner(isOpen: boolean) {
    this.showCustomersDataLoadingSpinner$.next(isOpen);
  }

  setFilteredCustomerSummaries(customerSummaries: CustomerSummary[]) {
    this.filteredCustomerSummaries$.next([...customerSummaries]);
  }

  setRetrievedCustomer(customer: any) {
    this.retrievedCustomer$.next({ ...customer });
  }
  setFavorites(customerSummaries: CustomerSummary[]): void {
    this.favoritesSubject.next([...customerSummaries]);
  }

  getRetrievedCustomer$(): Observable<Customer> {
    return this.retrievedCustomer$.asObservable();
  }

  getFilteredCustomerSummaries$(): Observable<CustomerSummary[]> {
    return this.filteredCustomerSummaries$.asObservable();
  }

  getCustomersDataLoadingSpinner$(): Observable<boolean> {
    return this.showCustomersDataLoadingSpinner$.asObservable();
  }

  getFavoritesSummaries$(): Observable<CustomerSummary[]> {
    return this.favoritesSummaries$;
  }

  retrieveCustomerFavoritesIfNeeded(): void {
    combineLatest([
      this.getFavoritesSummaries$(),
      this.userService.getUserPreferencesV2(),
    ])
      .pipe(take(1))
      .subscribe(([currentFavsSummaries, preferences]) => {
        if (
          !preferences?.favoriteCustomers ||
          preferences.favoriteCustomers.length === 0
        ) {
          this.setShowCustomersDataLoadingSpinner(false);
          return this.setFavorites([]);
        }
        const currentFavoritesList = new Set(
          (currentFavsSummaries ?? []).map(c => c?.customerNumber),
        );
        const userPreferencesFavorites = new Set(
          (preferences?.favoriteCustomers ?? []).map(c => c?.customerNumber),
        );

        const shouldUpdateFavorites =
          currentFavoritesList.size !== userPreferencesFavorites.size ||
          ![...userPreferencesFavorites].every(c =>
            currentFavoritesList.has(c),
          );

        if (shouldUpdateFavorites) {
          this.store.dispatch(
            CUSTOMER_ACTIONS.retrieveCustomerSummariesByKey({
              customerKeys: preferences.favoriteCustomers,
            }),
          );
        } else {
          this.setShowCustomersDataLoadingSpinner(false);
        }
      });
  }
}
