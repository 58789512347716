import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { defaultTransformer } from '@order/tracking/analytics/order-analytics.transformers';
import { Tracking } from '@panamax/app-state';
import { OrderItem } from '@usf/ngrx-order';
import { Product } from 'src/app/shared/models/product.model';
import { SharedTrackingService } from 'src/app/shared/services/analytics/shared-tracking.service';
import { ORDER_SUBMITTED_ANALYTICS_ACTIONS } from './order-submitted-analytics.actions';
import { OrderSubmittedAnalyticsMessages } from './order-submitted-analytics.constants';
import {
  cancelOrder,
  orderSubmittedDeeplinkTransformer,
  orderSubmittedPageLoad,
  submitEditOrderTracing,
  submitOrder,
} from './order-submitted-analytics.transformers';
import { ORDER_TRACING_ACTIONS } from '@order/tracking/tracing/order-tracing.actions';
import { genericAnalyticsTransformer } from '@shared/tracking/analytics/generic-analytics-transformer';

@Injectable({
  providedIn: 'root',
})
export class OrderSubmittedAnalyticsService {
  constructor(private store: Store) {}

  trackPageLoad(
    orderId: string,
    orderStatus: string,
    divisionNumber: number,
    orderItems: OrderItem[],
    productMap: Map<number, Product>,
    hasDeliveryDetails?: boolean,
  ): void {
    const products = SharedTrackingService.analyticsProductData(
      orderItems,
      productMap,
      divisionNumber,
    );
    let tracking: Tracking;
    if (hasDeliveryDetails) {
      tracking = {
        analytics: {
          data: {
            pageName: OrderSubmittedAnalyticsMessages.pageName,
            event: OrderSubmittedAnalyticsMessages.pageLoadedEvent,
            capability: OrderSubmittedAnalyticsMessages.capability,
            orderId: orderId,
            orderStatus: orderStatus,
            products: products,
            wheresMyTruck: {
              numberOfShipments: 1,
            },
          },
          transformFunc: orderSubmittedPageLoad,
        },
      };
    } else {
      tracking = {
        analytics: {
          data: {
            pageName: OrderSubmittedAnalyticsMessages.pageName,
            event: OrderSubmittedAnalyticsMessages.pageLoadedEvent,
            capability: OrderSubmittedAnalyticsMessages.capability,
            orderId: orderId,
            orderStatus: orderStatus,
            products: products,
          },
          transformFunc: orderSubmittedPageLoad,
        },
      };
    }

    this.store.dispatch(
      ORDER_SUBMITTED_ANALYTICS_ACTIONS.trackPageLoad({ tracking }),
    );
  }

  trackEditSubmittedOrderApiSuccess(trackingData: Tracking) {
    const tracking: Tracking = {
      analytics: {
        data: {
          ...trackingData.analytics.data,
          event: 'order submitted',
          capability: 'orders',
        },
        transformFunc: submitOrder,
      },
      tracing: {
        data: {
          ...trackingData?.tracing?.data,
          traceContext: 'order',
        },
        transformFunc: submitEditOrderTracing,
      },
    };
    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceSubmitEditedOrderApiResponseSuccess({
        tracking,
      }),
    );
  }

  trackEditOrderButtonClick(orderId: string, orderStatus: string) {
    const tracking: Tracking = {
      analytics: {
        data: {
          event: OrderSubmittedAnalyticsMessages.editOrderEvent,
          capability: OrderSubmittedAnalyticsMessages.capability,
          order: {
            id: orderId,
            status: orderStatus,
          },
        },
        transformFunc: defaultTransformer,
      },
    };
    this.store.dispatch(
      ORDER_SUBMITTED_ANALYTICS_ACTIONS.trackEditOrderClick({
        tracking,
      }),
    );
  }

  trackDownloadOrder(orderId: string, orderStatus: string, fileType: string) {
    const tracking: Tracking = {
      analytics: {
        data: {
          event: OrderSubmittedAnalyticsMessages.sharePrintDownloadEvent,
          capability: OrderSubmittedAnalyticsMessages.capability,
          order: {
            id: orderId,
            status: orderStatus,
          },
          file: {
            type: fileType,
          },
        },
        transformFunc: defaultTransformer,
      },
    };
    this.store.dispatch(
      ORDER_SUBMITTED_ANALYTICS_ACTIONS.trackDownloadOrder({
        tracking,
      }),
    );
  }

  trackTileEditOrderButtonClick(orderId: string, orderStatus: string) {
    const tracking: Tracking = {
      analytics: {
        data: {
          event: OrderSubmittedAnalyticsMessages.editOrderEvent,
          capability: OrderSubmittedAnalyticsMessages.capability,
          order: {
            id: orderId,
            status: orderStatus,
          },
        },
        transformFunc: defaultTransformer,
      },
    };
    this.store.dispatch(
      ORDER_SUBMITTED_ANALYTICS_ACTIONS.trackTileEditOrderButtonClick({
        tracking,
      }),
    );
  }
  trackShipToSubmit(orderId: string): void {
    let tracking: Tracking;
    tracking = {
      analytics: {
        data: {
          capability: OrderSubmittedAnalyticsMessages.capability,
          event: OrderSubmittedAnalyticsMessages.submitShipTo,
          order: {
            id: orderId,
          },
        },
        transformFunc: genericAnalyticsTransformer,
      },
    };

    this.store.dispatch(
      ORDER_SUBMITTED_ANALYTICS_ACTIONS.trackShipToSubmit({ tracking }),
    );
  }
  trackOrderSubmittedDeeplink() {
    const tracking: Tracking = {
      analytics: {
        data: {
          event: OrderSubmittedAnalyticsMessages.emailDeeplinkEvent,
          capability: OrderSubmittedAnalyticsMessages.alertsCapability,
          link: {
            type: OrderSubmittedAnalyticsMessages.emailDeeplinkType,
          },
        },
        transformFunc: orderSubmittedDeeplinkTransformer,
      },
    };
    this.store.dispatch(
      ORDER_SUBMITTED_ANALYTICS_ACTIONS.trackOrderSubmittedDeeplink({
        tracking,
      }),
    );
  }
}
