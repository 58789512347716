import { Injectable } from '@angular/core';
import { Tracking } from '@panamax/app-state';
import { ListAnalyticsService, ListConstants, ListState } from '@usf/ngrx-list';
import { ProductPropertiesEnum, ProductSummary } from '@usf/product-types';
import { Product } from 'src/app/shared/models/product.model';
import { ProductAlternative } from '../../product-detail/models/product-alternative';
import {
  didOrderSubstitute,
  isOriginalStatus0,
} from '../../product-detail/utils/product-analytics.util';
import { QuantityUpdate } from '../../shared/models/quantity-update';
import { mapProductPropertiesToAnalyticsTrackingProperties } from '../../shared/selectors/helpers/product-info.selectors.helper';
import { OrderItemService } from '../../shared/services/order-item.service';
import {
  ListRow,
  ProductRow,
} from '../shared/list-detail-management/model/list-detail-management-view.model';
import { BetterBuysConstants } from '@app/better-buys/constants/better-buys-constants.enum';

@Injectable({
  providedIn: 'root',
})
export class ListAnalyticsHelperService {
  constructor(
    private listAnalytics: ListAnalyticsService,
    private orderItemService: OrderItemService,
  ) {}

  recentPurchaseAddToOrder(
    event: any,
    product: Product,
    alternative?: ProductAlternative,
  ) {
    let productData = this.generateAddToOrderProductData(
      event,
      product,
      alternative,
    );
    if (event.betterBuy) {
      productData = this.generateBetterBuyProductData(
        productData,
        product,
        event.betterBuy,
      );
    }
    const trackingData: Tracking = {
      analytics: {
        data: {
          products: productData,
          list: {
            id: 'recently purchased',
          },
        },
      },
    };
    this.orderItemService.changeQuantity(
      event.productNumber,
      event.quantity,
      event.isEaches,
      trackingData,
    );
  }

  listDetailAddToOrder(
    event: any,
    list: ListState,
    product: Product,
    alternative?: ProductAlternative,
  ) {
    let productData = this.generateAddToOrderProductData(
      event,
      product,
      alternative,
    );
    if (event.betterBuy) {
      productData = this.generateBetterBuyProductData(
        productData,
        product,
        event.betterBuy,
      );
    }
    const trackingData: Tracking = {
      analytics: {
        data: {
          products: productData,
          list: {
            id: list.listKey.listTypeId + '-' + list.listKey.listId,
            listType: list.listState,
          },
        },
      },
    };
    this.orderItemService.changeQuantity(
      event.productNumber,
      event.quantity,
      event.isEaches,
      trackingData,
    );
  }

  quickViewAddToOrder(
    event: QuantityUpdate,
    list: ListState,
    product: ProductRow,
    alternative?: ProductAlternative,
  ) {
    let listData: any = {
      id: list?.listKey.listTypeId + '-' + list?.listKey.listId,
    };
    if (product.masterListItem) {
      listData.listType = 'master list';
      listData.id = product.masterListItem.listId;
    } else if (!list) {
      listData.id = 'recently purchased';
    } else if (list.listKey.listTypeId === 'SL') {
      listData.listType = list.listState;
    }
    let productData = this.generateAddToOrderProductData(
      event,
      product,
      alternative,
    );
    productData[0] = { ...productData[0], quickView: '1' };
    if (productData[1]) {
      productData[1] = { ...productData[1], quickView: '1' };
    }
    const trackingData: Tracking = {
      analytics: {
        data: {
          products: productData,
          list: listData,
        },
      },
    };
    this.orderItemService.changeQuantity(
      event.productNumber,
      event.quantity,
      event.isEaches,
      trackingData,
    );
  }

  trackListDetailPageLoad(listId: string, listRows: ListRow[]) {
    let products = this.generateDetailPageLoadProductData(listRows);
    if (products.length > 300) {
      products = [];
    }
    this.listAnalytics.trackListDetailPageLoad(listId, products);
  }

  trackListManagementPageLoad(listId: string) {
    this.listAnalytics.trackListManagementPageLoad(listId);
  }

  private generateAddToOrderProductData(
    event: QuantityUpdate,
    product: Product,
    alternative?: ProductAlternative,
  ) {
    let additionalProductData: any = [];
    if (
      isOriginalStatus0(product.inventory, alternative, event.productNumber)
    ) {
      additionalProductData[0] = {
        addMethod: 'list',
        orderedOriginal: '1',
        attributes: product?.trackingAttributes,
      };
    } else if (didOrderSubstitute(alternative, event.productNumber)) {
      const ogApn =
        product.summary.divisionNumber + '-' + product.productNumber;
      additionalProductData[0] = {
        addMethod: 'list',
        subIn: '1',
        substitutionParentApn: ogApn,
        attributes: product?.alternative?.product?.trackingAttributes,
      };
      additionalProductData[1] = {
        subOut: '1',
        divisionApn: ogApn,
        attributes: product?.trackingAttributes,
      };
    } else {
      additionalProductData[0] = {
        addMethod: 'list',
        attributes: product?.trackingAttributes,
      };
    }
    return additionalProductData;
  }

  private generateBetterBuyProductData(
    products: any[],
    product: Product,
    betterBuy?: any,
  ) {
    const ogApn = `${product.summary.divisionNumber}-${product.productNumber}`;
    if (products && products[0] != null) {
      products[0].addMethod = 'list';
      products[0].bbSwapIn = 1;
      products[0].substitutionParentApn = ogApn;
      products[0].attributes = betterBuy?.product?.trackingAttributes;
      products[0].merchFeatureName = BetterBuysConstants.betterBuys;
    }
    if (products && products[1] != null) {
      products[1].bbSwapOut = 1;
      products[1].divisionApn = ogApn;
      products[1].attributes = product?.trackingAttributes;
    } else {
      products[1] = {
        bbSwapOut: 1,
        divisionApn: ogApn,
        attributes: product?.trackingAttributes,
      };
    }
    return products;
  }

  private generateDetailPageLoadProductData(listRows: ListRow[]): any[] {
    let productData = [];
    listRows?.forEach(row => {
      let tempRow = row as any;
      if (tempRow.alternative?.required && tempRow.alternative?.available) {
        // Push data for og
        productData.push({
          divisionApn:
            tempRow.summary.divisionNumber +
            '-' +
            tempRow.summary.productNumber,
          attributes: mapProductPropertiesToAnalyticsTrackingProperties(
            tempRow.summary.properties,
            tempRow.summary.additionalProperties,
          ),
          exceptionView: '1',
          exceptionNumberofSubs: '1',
          casePrice: tempRow.pricing.unitPrice,
          eachPrice:
            tempRow.summary.breakable && !tempRow.summary.catchWeightFlag
              ? tempRow.pricing.eachPrice
              : null,
        });
        // Push data for sub
        productData.push({
          divisionApn:
            tempRow.alternative.product.summary.divisionNumber +
            '-' +
            tempRow.alternative.product.summary.productNumber,
          attributes: mapProductPropertiesToAnalyticsTrackingProperties(
            tempRow.alternative.product.summary.properties,
            tempRow.alternative.product.summary.additionalProperties,
          ),
          substitutionView: '1',
          substitutionParentAPN:
            tempRow.summary.divisionNumber +
            '-' +
            tempRow.summary.productNumber,
          casePrice: tempRow.alternative.product.pricing.unitPrice,
          eachPrice:
            tempRow.alternative.product.summary.breakable &&
            !tempRow.alternative.product.summary.catchWeightFlag
              ? tempRow.alternative.product.pricing.eachPrice
              : null,
        });
      } else {
        //Push data for unavailable products
        const isUnavailable: boolean = tempRow?.inventory?.productStatus == '2';
        const isOutOfStockOrDiscontinued = tempRow?.summary?.properties
          ? tempRow?.summary?.properties.has(
              ProductPropertiesEnum.discontinued,
            ) ||
            tempRow?.summary?.properties.has(ProductPropertiesEnum.outOfStock)
          : false;
        if (
          !tempRow.hasAlternative &&
          (isUnavailable || isOutOfStockOrDiscontinued)
        ) {
          productData.push({
            divisionApn:
              tempRow.summary.divisionNumber +
              '-' +
              tempRow.summary.productNumber,
            attributes: mapProductPropertiesToAnalyticsTrackingProperties(
              tempRow.summary.properties,
              tempRow.summary.additionalProperties,
            ),
            exceptionView: '1',
            casePrice: tempRow.pricing.unitPrice,
            eachPrice:
              tempRow.summary.breakable && !tempRow.summary.catchWeightFlag
                ? tempRow.pricing.eachPrice
                : null,
          });
        }
      }
    });
    return productData;
  }

  masterListsPageLoad(listId: string, listRows: any[]) {
    let products = this.generateDetailPageLoadProductData(listRows);
    if (products.length > 300) {
      products = [];
    }
    this.listAnalytics.trackMasterListsPageLoaded(listId, products);
  }

  masterListAddToOrder(event: any, product: ProductRow) {
    let productData = this.generateAddToOrderProductData(
      event,
      product,
      product.alternative,
    );
    if (event.betterBuy) {
      productData = this.generateBetterBuyProductData(
        productData,
        product,
        event.betterBuy,
      );
    }
    const trackingData: Tracking = {
      analytics: {
        data: {
          products: productData,
          list: {
            id: product.masterListItem.listId,
            listType: 'master list',
          },
        },
      },
    };
    this.orderItemService.changeQuantity(
      event.productNumber,
      event.quantity,
      event.isEaches,
      trackingData,
    );
  }

  downloadPrintList(
    listId: number,
    listType: string,
    listState: string,
    entryPoint: string,
  ) {
    const listIdForAnalytics = listType + '-' + listId;
    const listTypeId = this.formatListType(listType, listState);
    this.listAnalytics.trackDownloadPrint(
      listIdForAnalytics,
      listTypeId,
      entryPoint,
    );
  }

  private formatListType(listType: string, listState: string) {
    switch (listType) {
      case 'SL':
        return listState;
      case 'OG':
        return 'order guide';
      case 'ML':
        return 'master list';
    }
  }

  trackSearchSubs(
    productSummary: ProductSummary,
    trackingAttributes: string,
    quickView: boolean = false,
  ) {
    const term = this.toTitleCase(productSummary.productDescTxtl);
    const products = [
      {
        divisionApn: `${productSummary.divisionNumber}-${productSummary.productNumber}`,
        attributes: trackingAttributes,
        quickView: '1',
      },
    ];
    if (!quickView) {
      delete products[0].quickView;
    }
    this.listAnalytics.trackSearchSubs(products, term);
  }

  trackAddToList(analyticsData: {
    products: any[];
    listID?: number;
    listType?: string;
    addMethod?: string;
    merchFeatureName?: string;
    pageName?: string;
  }) {
    this.listAnalytics.trackAddToList(analyticsData);
  }

  private toTitleCase(str: string): string {
    return str
      .split(' ')
      .slice(0, 3)
      .join(' ')
      .replace(',', '')
      .replace(/\w\S*/g, txt => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  }
}
