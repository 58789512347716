import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToggleNewSearchService {
  public static readonly NEW_SEARCH_ENABLED = 'toggle-new-search';
  private newSearchEnabledSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  private newSearchEnabled$: Observable<boolean>;

  constructor() {
    const enabled =
      sessionStorage.getItem(ToggleNewSearchService.NEW_SEARCH_ENABLED) ===
      'true';
    this.newSearchEnabledSubject.next(enabled);
    this.newSearchEnabled$ = this.newSearchEnabledSubject.asObservable();
  }

  toggle() {
    const isToggleOn = !this.newSearchEnabledSubject.value;
    sessionStorage.setItem(
      ToggleNewSearchService.NEW_SEARCH_ENABLED,
      isToggleOn.toString(),
    );
    this.newSearchEnabledSubject.next(isToggleOn);
  }

  isNewSearchEnabled(): Observable<boolean> {
    return this.newSearchEnabledObservable$;
  }

  public get newSearchEnabledObservable$(): Observable<boolean> {
    return this.newSearchEnabled$;
  }
}
