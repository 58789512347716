import { Injectable, OnDestroy } from '@angular/core';
import { selectCustomMessages } from '@app/user/store';
import { UserActions } from '@app/user/store/actions/action-types';
import { FileOpener } from '@capacitor-community/file-opener';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AuthConnectService, PanAppState, UserState } from '@panamax/app-state';
import { NONE } from '@shared/constants/files';
import { UserKinds } from '@usf/user-types/user';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  of,
  throwError,
} from 'rxjs';
import { catchError, filter, map, skip, take } from 'rxjs/operators';
import { UserService } from 'src/app/user/services';
import { environment } from 'src/environments/environment';
import { ProfileAnalyticsService } from '../analytics/profile-analytics.service';
import { selectedCustomer } from '@app/ngrx-customer/store';
import { Customer } from '@usf/customer-types';
import { ServiceHandlerService } from '@shared/services/service-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService implements OnDestroy {
  profileUnchanged$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  profileInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
  userID: number;
  userEmailAndPhoneSub$: Subscription;

  constructor(
    readonly panAppState: PanAppState,
    private userService: UserService,
    private store: Store,
    private authConnect: AuthConnectService,
    private profileAnalyticsService: ProfileAnalyticsService,
    private platform: Platform,
    private serviceHandler: ServiceHandlerService,
  ) {}

  ngOnDestroy(): void {
    if (this.userEmailAndPhoneSub$) {
      this.userEmailAndPhoneSub$.unsubscribe();
    }
  }

  setProfile() {
    this.panAppState.user$
      .pipe(
        filter(
          (user: UserState) =>
            !!user &&
            !!user.userKind &&
            ((user.userKind === UserKinds.External && !!user.profiles) ||
              user.userKind !== UserKinds.External),
        ),
        catchError(error => {
          return of({ error });
        }),
        take(1),
      )
      .subscribe((user: UserState) => {
        if (user.userKind) {
          let ecomUserId = user.ecomUserId;
          this.userID = user.ecomUserId;
          let userKind = user.userKind;
          let contactInfo = {
            firstName: user.firstName,
            lastName: user.lastName,
            organizationName: user.organizationName ?? '',
            notificationEmail: user.email,
            confirmNotificationEmail: '',
          };
          let loginAndSecurity = {
            loginEmail: 'N/A',
            loginPhoneNumber: 'N/A',
            userName: user.userId,
            userSince: user.addDtm,
            lastLoginDtm: user.lastLoginDtm,
          };

          if (user.userKind === UserKinds.External) {
            this.store.dispatch(
              UserActions.getEmailPhoneByUsername({ username: user.userId }),
            );
            this.userEmailAndPhoneSub$ = this.userService
              .userEmailAndPhone$()
              .pipe(
                skip(1),
                filter(data => !!data),
                catchError(error => {
                  return of({ error });
                }),
              )
              .subscribe(
                (data: { phone?: string; email?: string; error?: any }) => {
                  if (!data.error) {
                    loginAndSecurity['loginEmail'] = this.hideEmail(data.email);
                    loginAndSecurity['loginPhoneNumber'] = this.hidePhone(
                      data.phone,
                    );
                    this.profileInfo$.next({
                      ecomUserId,
                      contactInfo,
                      loginAndSecurity,
                      userKind,
                    });
                  } else {
                    this.profileInfo$.next({ error: 'User not found' });
                  }
                },
              );
          } else {
            this.profileInfo$.next({
              ecomUserId,
              contactInfo,
              loginAndSecurity,
              userKind,
            });
          }
        } else {
          this.profileInfo$.next({ error: 'User not found' });
        }
      });
  }

  getProfile() {
    return this.profileInfo$.asObservable();
  }

  getProfileUnchanged() {
    return this.profileUnchanged$.asObservable();
  }

  getUserId(): number {
    return this.userID;
  }

  setProfileUnchanged(value: boolean) {
    this.profileUnchanged$.next(value);
  }

  hideEmail(email: string) {
    if (!email) return 'N/A';
    let emailNameAndVendor = email.split('@');
    let emailName = emailNameAndVendor[0];
    let firstLetter = emailName[0];
    let lastLetter = emailName[emailName.length - 1];
    let hiddenCar = emailName.length > 8 ? 6 : emailName.length - 2;
    let name =
      firstLetter +
      emailName.split('').fill('*').slice(0, hiddenCar).join('') +
      lastLetter;
    let hiddenEmail = [name, emailNameAndVendor[1]].join('@');
    return hiddenEmail;
  }

  hidePhone(phone: string) {
    return phone ? '***-***-' + phone.slice(-4) : 'N/A';
  }

  async changeLoginInformation(targetUserName: string) {
    const url: string = environment.azureChangeLoginInformation;
    try {
      await this.authConnect.additionalLoginParameters({ targetUserName });
      await this.authConnect.clearOverrideDiscoveryUrl();
      await this.authConnect.setOverrideDiscoveryUrl(url);
      await this.authConnect.login();
    } catch (error) {
      return null;
    }
  }

  trackPageLoad() {
    this.profileAnalyticsService.trackPageLoad();
  }

  getCustomMessages() {
    this.store.dispatch(UserActions.getCustomMessages());
    return this.store.select(selectCustomMessages);
  }

  downloadCustomerList(customers: any[]) {
    let fileContent = 'Customer Number,Division Number\r\n';
    customers.forEach(c => {
      fileContent += c.customerNumber + ',' + c.divisionNumber + '\r\n';
    });
    this.downloadFile(fileContent);
  }

  downloadTemplate() {
    const fileContent = 'Customer Number,Division Number\r\n';
    this.downloadFile(fileContent);
  }

  async downloadFile(fileContent: string) {
    try {
      const fileNameWithExtension = 'CustomerList.csv';
      const contentTypeString = this.platform.is('android')
        ? 'text/comma-separated-values'
        : 'text/csv';
      if (this.platform.is('hybrid')) {
        const savedFile = await Filesystem.writeFile({
          path: fileNameWithExtension,
          data: fileContent,
          directory: Directory.Documents,
          encoding: Encoding.UTF8,
        });
        FileOpener.open({
          filePath: savedFile.uri,
          contentType: contentTypeString,
        });
      } else {
        const blob = new Blob([fileContent], { type: 'text/csv' });
        const blobURL = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.style.display = NONE;
        link.download = fileNameWithExtension;
        link.href = blobURL;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (e) {
      console.log('Download file error: ', JSON.stringify(e));
    }
  }

  async deleteCustomMessage(messageId: string) {
    this.store.dispatch(
      UserActions.deleteCustomMessage({ messageIds: [messageId] }),
    );
    this.store.dispatch(UserActions.getCustomMessages());
  }

  saveCustomMessage(customMessage: any) {
    this.store.dispatch(
      UserActions.saveCustomMessage({ customMessage: customMessage }),
    );
    this.store.dispatch(UserActions.getCustomMessages());
  }

  getSelectedCustomer(): Observable<Customer> {
    return this.store.select(selectedCustomer);
  }

  getSurveyLink(): Observable<string> {
    return this.serviceHandler
      .get<any>(`${environment.alertsApiUrl}/surveyLink`)
      .pipe(
        take(1),
        filter(res => !!res && !!res.surveyLink),
        map(res => res.surveyLink),
        catchError(error => throwError(() => error)),
      );
  }
}
