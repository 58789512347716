import { createAction, props } from '@ngrx/store';
import { Customer, CustomerSummary } from '@usf/customer-types/customer';
import { CustomerSwitchStatus } from '../../constants/customer-switch-status';
import { CustomerKey, UserCustomer } from '@usf/customer-types';

export const getCustomerList = createAction('[Customers] Get Customer List');
export const getCustomerListSuccess = createAction(
  '[Customers] Get Customer List Success',
  props<{ customers: Customer[] }>(),
);
export const getCustomerListFail = createAction(
  '[Customers] Get Customer List Fail',
  props<{ error: any }>(),
);

export const CUSTOMER_SWITCH_ACTIONS = {
  getCustomerSwitchStatus: createAction(
    '[Customers] Get Customer Switch Status',
  ),
  setCustomerSwitchStatus: createAction(
    '[Customers] Set Customer Switch Status',
    props<{ customerSwitchStatus: CustomerSwitchStatus }>(),
  ),
  resetCustomerSwitchStatus: createAction(
    '[Customers] Reset Customer Switch Status',
  ),
};

export const CUSTOMER_ACTIONS = {
  searchCustomers: createAction(
    '[NgRxCustomer] Search Customers',
    props<{
      searchKey: string;
    }>(),
  ),
  searchCustomersSuccess: createAction(
    '[NgRxCustomer] Search Customers Success',
    props<{
      customerSummaries: CustomerSummary[];
    }>(),
  ),
  searchCustomersFail: createAction(
    '[NgRxCustomer] Search Customers Fail',
    props<{
      error: any;
    }>(),
  ),

  retrieveCustomer: createAction(
    '[NgRxCustomer] Retrieve Customer',
    props<{
      customerNumber: number;
      divisionNumber: number;
    }>(),
  ),
  retrieveCustomerSuccess: createAction(
    '[NgRxCustomer] Retrieve Customer Success',
    props<{
      customer: any;
    }>(),
  ),
  retrieveCustomerFail: createAction(
    '[NgRxCustomer] Retrieve Customer Fail',
    props<{
      error: any;
    }>(),
  ),
  retrieveCustomerSummariesByKey: createAction(
    '[NgRxCustomer] Retrieve Customer Summaries By Key',
    props<{
      customerKeys: CustomerKey[];
    }>(),
  ),
  retrieveCustomerSummariesByKeySuccess: createAction(
    '[NgRxCustomer] Retrieve Customer Summaries By Key Success',
    props<{
      customerSummaries: CustomerSummary[];
    }>(),
  ),
  retrieveCustomerSummariesByKeyFail: createAction(
    '[NgRxCustomer] Retrieve Customer Summaries By Key Fail',
    props<{
      error: any;
    }>(),
  ),
};

export const getActiveCustomersByUserId = createAction(
  '[Customers] Get Active Customers By UserId',
  props<{ userId: number }>(),
);
export const getActiveCustomersByUserIdSuccess = createAction(
  '[Customers] Get Active Customers By UserId Success',
  props<{ userCustomer: UserCustomer }>(),
);
export const getActiveCustomersByUserIdFail = createAction(
  '[Customers] Get Active Customers By UserId Fail',
  props<{ error: any }>(),
);

export const updateCustomersByUserId = createAction(
  '[Customers] Update Customers By UserId',
  props<{
    userId: number;
    customerIds: { customerNumber: number; divisionNumber: number }[];
  }>(),
);
export const updateCustomersByUserIdSuccess = createAction(
  '[Customers] Update Customers By UserId Success',
);
export const updateCustomersByUserIdFail = createAction(
  '[Customers] Update Customers By UserId Fail',
  props<{ error: any }>(),
);
