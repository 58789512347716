import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrderService } from '@order/services/order.service';
import {
  LOCAL_STORAGE_KEYS,
  PLATFORM_ACTIONS,
  PanAppState,
  TokenAuthContext,
  TokenAuthMode,
  UsfTokenService,
} from '@panamax/app-state';
import { PATHS } from '@shared/constants/paths';
import { FEATURES } from '@shared/constants/splitio-features';
import { NEXT_DELIVERY_ACTIONS } from '@usf/ngrx-order';
import { throwError } from 'rxjs';
import {
  catchError,
  exhaustMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { getCustomerList } from 'src/app/ngrx-customer/store/actions/customer.actions';
import { getDivisionList } from 'src/app/ngrx-customer/store/actions/division.actions';
import { UserActions } from 'src/app/user/store/actions/action-types';
import { CustomerSwitchStatus } from '../../../ngrx-customer/constants/customer-switch-status';
import { CustomerStoreService } from '../../../ngrx-customer/services';
import { LoginActions } from '../actions/action-types';
import { MessageTypeEnum } from '@app/ngrx-message/constants/messageTypeEnum';
import { Message } from '@app/ngrx-message/models/client/message';
import { MessageStoreService } from '@app/ngrx-message/services/message/message-store.service';
import { ToastMessages } from '@shared/constants/toast-messages.enum';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '@app/login/services';
import { Router } from '@angular/router';
import {
  IAppState,
  TokenAuthOptions,
  UsfTokenStorageService,
} from '@panamax/app-state';
import { loadOrderControl } from '../actions';

@Injectable({
  providedIn: 'root',
})
export class LoginEffects {
  newSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PLATFORM_ACTIONS.newSession),
      tap(() => {
        console.log('Firing new session action');
        this.resetOrderData();
        this.resetDeliveryData();

        this.tokenStorageService
          .getOptions()
          .pipe(take(1))
          .subscribe(authOptions => {
            if (authOptions) {
              this.loginService.zipCode = authOptions.zipCode;
              this.store.dispatch(loadOrderControl());
            }
          });
      }),
      exhaustMap(action => [
        UserActions.getUserByToken(),
        getCustomerList(),
        getDivisionList(),
      ]),
      catchError(err => {
        // this.clickStream.login(false, err);
        // const loginError = { status: 1005, message: err.message };
        // LoginActions.microsoftLoginFailed({
        //   loginError,
        //   queryParams: {} as Params,
        // });
        console.error(err);
        return throwError(err);
        // );
      }),
    ),
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginActions.logout),
        withLatestFrom(this.panamax.feature$([FEATURES.split_global_appcues])),
        tap(([action, appcuesEnabled]) => {
          if (appcuesEnabled) {
            window['Appcues'].reset();
          }
          localStorage.removeItem(LOCAL_STORAGE_KEYS.token);
          localStorage.removeItem(LOCAL_STORAGE_KEYS.params);
          localStorage.removeItem(LOCAL_STORAGE_KEYS.impersonator);
          this.panamax.clearTokenState();
          this.store.dispatch(LoginActions.resetStore());
          this.tokenService.logout();
        }),
      ),
    { dispatch: false },
  );

  punchout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginActions.punchout),
        tap(() => {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.token);
          localStorage.removeItem(LOCAL_STORAGE_KEYS.params);
          localStorage.removeItem(LOCAL_STORAGE_KEYS.impersonator);
          this.panamax.clearTokenState();
          this.store.dispatch(LoginActions.resetStore());
          this.tokenService.logout([PATHS.PUNCHOUT]);
        }),
      ),
    { dispatch: false },
  );

  refreshTokenWithContext$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginActions.refreshTokenWithContext),
        tap(action => {
          this.customerStoreService.setCustomerSwitchStatus(
            CustomerSwitchStatus.pending,
          );
          const context: TokenAuthContext = action.authContext;
          this.tokenService.refresh(context).catch(() => {
            this.messageStoreService.upsertMessage({
              type: MessageTypeEnum.error,
              display: this.translateService.instant(
                ToastMessages.customerSwitchError,
              ),
              toast: true,
              sticky: false,
            } as Message);
            this.customerStoreService.setCustomerSwitchStatus(
              CustomerSwitchStatus.failed,
            );
            console.error('Customer Switch Failed');
          });
        }),
        //TODO: this is missing tracing/tracking
      ),
    { dispatch: false },
  );

  switchGuestZipCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginActions.switchGuestZipCode),
        tap(action => {
          this.customerStoreService.setCustomerSwitchStatus(
            CustomerSwitchStatus.pending,
          );
          const zip: number = action.zipCode;
          this.tokenService
            .authorize(TokenAuthMode.Guest, { zipCode: zip })
            .then(() => {
              this.loginService.zipCode = zip;
              this.router.navigate([PATHS.SEARCH_BROWSE]);
              this.store.dispatch(loadOrderControl());
            })
            .catch(() => {
              this.messageStoreService.upsertMessage({
                type: MessageTypeEnum.error,
                display: this.translateService.instant(
                  ToastMessages.guestSwitchError,
                ),
                toast: true,
                sticky: false,
              } as Message);
              console.error('Zip code Switch Failed');
              this.customerStoreService.setCustomerSwitchStatus(
                CustomerSwitchStatus.failed,
              );
            });
        }),
      ),
    { dispatch: false },
  );

  resetOrderData() {
    if (!sessionStorage.getItem('orderInCart')) {
      this.orderService.resetOrder();
    }
  }

  resetDeliveryData() {
    this.store.dispatch(NEXT_DELIVERY_ACTIONS.resetStore());
  }

  resetCustomerSwitchStatusData() {
    this.customerStoreService.resetCustomerSwitchStatus();
  }

  constructor(
    private actions$: Actions,
    private panamax: PanAppState,
    private store: Store,
    private customerStoreService: CustomerStoreService,
    private orderService: OrderService,
    private tokenService: UsfTokenService,
    private messageStoreService: MessageStoreService,
    private translateService: TranslateService,
    private loginService: LoginService,
    private router: Router,
    private tokenStorageService: UsfTokenStorageService,
  ) {}
}
