/* eslint-disable no-shadow */
export enum ChipTypeEnum {
  /***
   * Black border, black text
   */
  default = 'default',
  /***
   * Default but not text capitalization
   */
  contract = 'contract',
  /***
   * Black border, red text
   */
  boldRed = 'boldRed',
  /***
   * Claim
   */
  claim = 'claim',
  /***
   * Image
   */
  image = 'image',
  /***
   * Contract Image
   */
  contractImage = 'contractImage',
  /***
   * Selected Filter
   */
  selectedFilter = 'selected-facets',
  /***
   * Customer Pill
   */
  customerPill = 'customer-pill',
  /**
   * No Icon Facet
   */
  noIconFacet = 'no-icon-facet',
}

/* eslint-enable no-shadow */
