import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrderControl } from '../models/order-control';

@Injectable({
  providedIn: 'root',
})
export class OrderControlService {
  private readonly retrievedOrderControlSubject = new BehaviorSubject<
    OrderControl[]
  >([]);
  private readonly retrievedOrderControl$ =
    this.retrievedOrderControlSubject.asObservable();

  setRetrievedOrderControl(orderControl: OrderControl[]) {
    this.retrievedOrderControlSubject.next(orderControl);
  }

  getRetrievedOrderControl$(): Observable<OrderControl[]> {
    return this.retrievedOrderControl$;
  }
}
