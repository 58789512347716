import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, shareReplay } from 'rxjs';
import { CustomerUser } from '../models/customer-user.model';
import { Store } from '@ngrx/store';
import { UserActions } from '@app/user/store/actions/action-types';
import { Customer, Department } from '@usf/customer-types';
import { selectedCustomer, selectedDepartment } from '@app/ngrx-customer/store';

@Injectable({
  providedIn: 'root',
})
export class ManageUsersService {
  currentCustomer$ = this.store.select(selectedCustomer);

  currentDepartment$ = this.store.select(selectedDepartment);

  customerUsersSubject = new BehaviorSubject<CustomerUser[]>([]);
  private customerUsersObservable$ = this.customerUsersSubject.asObservable();

  customerUsersCallCompleteSubject = new BehaviorSubject<boolean>(false);
  private customerUsersCallCompleteObservable$ =
    this.customerUsersCallCompleteSubject.asObservable();

  constructor(private store: Store) {}

  getCurrentCustomer$(): Observable<Customer> {
    return this.currentCustomer$;
  }

  getCurrentDepartment$(): Observable<Department> {
    return this.currentDepartment$;
  }

  loadAllUsersForCustomer(customerNumber?: number): void {
    this.store.dispatch(UserActions.getCustomerUsers());
  }

  setCustomerUsers(customerUsers: CustomerUser[]): void {
    this.customerUsersSubject.next([...customerUsers]);
  }

  getCustomerUsers$(): Observable<CustomerUser[]> {
    return this.customerUsersObservable$;
  }
  setCustomerUsersCallComplete(isCallComplete: boolean): void {
    this.customerUsersCallCompleteSubject.next(isCallComplete);
  }
  isCustomerUsersCallComplete$(): Observable<boolean> {
    return this.customerUsersCallCompleteObservable$;
  }

  removeUserAdmin(user: CustomerUser): CustomerUser {
    const updatedUser = { ...user, admin: false };
    console.log('Removing admin from user: ', updatedUser);
    return updatedUser;
  }
  makeUserAdmin(user: CustomerUser): CustomerUser {
    const updatedUser = { ...user, admin: true };
    console.log('Converting user to admin: ', updatedUser);
    return updatedUser;
  }

  removeUser(user: CustomerUser): CustomerUser {
    console.log('Removing user from MOXe: ', user);
    return user;
  }

  // This is a temporary method for user data
  // TODO: remove this method when API work is completed and we can use real user data
  generateUserData(): Observable<CustomerUser[]> {
    const firstNames = ['Sophia', 'Ava', 'Noah', 'Liam', 'Isabella'];
    const lastNames = ['Smith', 'Johnson', 'Brown', 'Williams', 'Taylor'];
    const domains = ['gmail.com', 'yahoo.com', 'outlook.com', 'mail.com'];

    const generateRandomDateWithinLastYear = (): Date => {
      const now = new Date();
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(now.getFullYear() - 1);
      const randomTime =
        oneYearAgo.getTime() +
        Math.random() * (now.getTime() - oneYearAgo.getTime());
      return new Date(randomTime);
    };

    const users: CustomerUser[] = firstNames.map((firstName, i) => {
      const lastName = lastNames[i % lastNames.length];
      const email = `${firstName.toLowerCase()}.${lastName.toLowerCase()}@${domains[i % domains.length]}`;
      return {
        userName: `${firstName[0]}${lastName[0]}${i + 1}`,
        firstName,
        lastName,
        email,
        lastLogin: generateRandomDateWithinLastYear(), // Random date within last year
        admin: i % 2 === 0, // Alternate admin status
      } as CustomerUser;
    });

    return of(users);
  }
}
