<div class="typeahead-search">
  <ion-searchbar
    #searchCatalog
    class="search"
    animated="true"
    autocapitalize="none"
    mode="md"
    placeholder="{{
      (isNewSearchEnabled
        ? 'i18n.header.newSearchCatalog'
        : 'i18n.header.searchProducts'
      ) | translate
    }}"
    [debounce]="typeaheadDebounce"
    (ionBlur)="handleInputBlur($event)"
    (ionInput)="handleInputChange($event)"
    (ionFocus)="handleInputFocus($event)"
    (keydown.enter)="handleSearch(searchCatalog)"
    (keydown)="handleArrowKey($event)"
    data-cy="typeahead-search-input"
    [attr.show-clear-button]="isClearButtonVisible ? 'focus' : 'never'"
  ></ion-searchbar>
  <div
    *ngIf="
      typeaheadVisible && !typeaheadNoResults && !isNewSearchEnabled;
      else newTypeahead
    "
    class="typeahead"
  >
    <div *ngFor="let group of customTypeAheadGroup">
      <h2 [attr.data-cy]="'group-' + group.name">{{ group.name }}</h2>
      <ul>
        <li
          *ngFor="let item of group.items; let i = index"
          (click)="handleItemClick(item, group.name, i + 1)"
          [attr.data-cy]="'click-item-' + item.description"
          [class.active]="item.isActive"
          class="active"
        >
          {{ item.description }}
        </li>
      </ul>
    </div>
  </div>
</div>
<ng-template #newTypeahead>
  <app-new-search-typeahead
    *ngIf="typeaheadVisible && isNewSearchEnabled"
    [searchCatalog]="searchCatalog"
    (isTypeaheadVisible)="onTypeaheadVisible($event)"
  ></app-new-search-typeahead>
</ng-template>
