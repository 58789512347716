import { AnalyticsTransformer, TrackingData } from '@panamax/app-state';

export const orderSubmittedPageLoad: AnalyticsTransformer = (
  tracking: TrackingData,
) => {
  let trackingObj = {
    page: {
      pageName: tracking.data.pageName,
    },
    order: {
      id: tracking.data.orderId,
      status: tracking.data.orderStatus,
    },
    event: tracking.data.event,
    capability: tracking.data.capability,
    products: tracking.data.products,
    wheresMyTruck: {
      numberOfShipments: tracking.data?.wheresMyTruck?.numberOfShipments,
    },
  };
  if (!tracking.data?.wheresMyTruck?.numberOfShipments)
    delete trackingObj.wheresMyTruck;
  return trackingObj;
};

export const cancelOrder: AnalyticsTransformer = (tracking: TrackingData) => ({
  order: {
    orderId: tracking.data.orderId,
    status: tracking.data.orderStatus,
  },
  event: tracking.data.event,
  capability: tracking.data.capability,
  products: tracking.data.products,
});

/**
 * SUBMIT ORDER
 *
 * @param tracking
 * @returns Data Layer needed for tracking the submit order event
 */
export const submitOrder = (tracking: TrackingData) => {
  const result = {
    event: tracking.data.event,
    capability: tracking.data.capability,
    order: tracking.data.order,
    products: tracking.data.products,
  };

  return result;
};

/**
 * SUBMIT ORDER SUCCESS TRACING
 *
 * @param tracking
 * @returns Data Layer needed for tracking the submit order tracing event
 */
export const submitOrderTracing = (tracking: TrackingData) => {
  const result = {
    event: tracking.data.attributes?.event,
    order: {
      orderId: tracking.data.attributes?.orderId,
    },
  };
  return result;
};

export const submitEditOrderTracing = (trackingData: TrackingData) => {
  const result = {
    event: trackingData.data.attributes?.event,
    order: {
      orderId: trackingData.data.attributes?.order?.orderId,
      tandemOrderNumber: trackingData.data.attributes?.order?.tandemOrderId,
      totalAmount: trackingData.data.attributes?.order?.totalAmount,
    },
    products: trackingData.data.attributes?.products,
  };
  return result;
};

export const orderSubmittedDeeplinkTransformer = (
  trackingData: TrackingData,
) => {
  const result = {
    event: trackingData.data.event,
    capability: trackingData.data.capability,
    link: {
      type: trackingData.data.link.type,
    },
  };
  return result;
};
