import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ServiceHandlerService } from 'src/app/shared/services/service-handler.service';
import { OrderControl } from '../models/order-control';

@Injectable({
  providedIn: 'root',
})
export class OrderControlDataService<T> {
  constructor(private readonly serviceHandler: ServiceHandlerService) {}

  private static readonly params = new HttpParams().set('mainApp', 'MOXE');

  retrieveOrderControl(): Observable<OrderControl[]> {
    const apiUrl = `${environment.orderApiUrl}/orderControl`;

    return this.serviceHandler
      .get<OrderControl[]>(apiUrl, OrderControlDataService.params)
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(() => error)),
      );
  }
}
