import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-selectable-item',
  templateUrl: './selectable-item.component.html',
  styleUrls: ['./selectable-item.component.scss'],
})
export class SelectableItemComponent {
  @Input() label: string;
  @Input() value: string;
  @Input() placeholder?: string;
  @Input() detailIcon: string;
  @Input() mediumFont?: boolean;

  @Output() clickValueEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  clickValue(event: any) {
    this.clickValueEmitter.emit(event);
  }
}
